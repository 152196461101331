import styled from 'styled-components';
import { Typography } from '../DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';
import * as React from 'react';
import MemoIconChevronUp from '../../shared/SchedulePage/icons/IconChevronUp';
import ReactSelect from 'react-select';
import axios from 'axios';
import { useQuery } from 'react-query';
/* eslint-disable react/prop-types */

export const FormErrorText = styled(Typography).attrs({
  variant: 'regular/12-20'
})`
  color: ${colorsV2.red100};
`;

export const FormLabel = styled(Typography).attrs({
  variant: 'medium/12-20'
})``;
export const FormInput = styled.input`
  border-radius: 8px;
  background-color: ${colorsV2.gray10};
  width: 100%;
  height: 46px;
  border: none;

  padding: 12px 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
export const FormItem = styled.div`
  ${FormLabel} {
    color: ${colorsV2.black60};
  }
`;
export const FormInputNumberWrapper = styled.div`
  display: flex;
  ${FormInput} {
    border-radius: 8px 0px 0px 8px;
    padding-right: 0;
  }
`;
const FormInputNumberBaseAction = styled.div`
  background-color: ${colorsV2.gray20};
  width: 24px;
  height: 23px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FormInputNumberStepUp = styled(FormInputNumberBaseAction)`
  border-radius: 0px 8px 0px 0px;
  border-bottom: 1px solid ${colorsV2.gray40};
`;
export const FormInputNumberStepDown = styled(FormInputNumberBaseAction)`
  border-radius: 0px 0px 8px 0px;

  svg {
    transform: rotate(180deg);
  }
`;
export const FormInputNumberAction = styled.div``;
export const FormInputNumber = props => {
  const inputRef = React.useRef();

  const stepUp = React.useCallback(() => {
    inputRef.current.stepUp();
    inputRef.current.dispatchEvent(new Event('input', { bubbles: true })); // trigger native onChange
  }, []);
  const stepDown = React.useCallback(() => {
    inputRef.current.stepDown();
    inputRef.current.dispatchEvent(new Event('input', { bubbles: true })); // trigger native onChange
  }, []);
  return (
    <FormInputNumberWrapper>
      <FormInput
        type="number"
        defaultValue="1"
        min="1"
        readOnly
        ref={inputRef}
        {...props}
      />
      <FormInputNumberAction>
        <FormInputNumberStepUp onClick={stepUp}>
          <MemoIconChevronUp />
        </FormInputNumberStepUp>
        <FormInputNumberStepDown onClick={stepDown}>
          <MemoIconChevronUp />
        </FormInputNumberStepDown>
      </FormInputNumberAction>
    </FormInputNumberWrapper>
  );
};
const fetchCity = async () => {
  return axios.get(`${process.env.NODE_ENDPOINT}/cities`).then(res => res.data);
};
const fetchDistricts = cityId => async () => {
  return axios
    .get(`${process.env.NODE_ENDPOINT}/districts?parentId=${cityId}`)
    .then(res => res.data);
};
const fetchWards = districtId => async () => {
  return axios
    .get(`${process.env.NODE_ENDPOINT}/wards?parentId=${districtId}`)
    .then(res => res.data);
};
const baseStyles = {
  control: defaults => ({
    ...defaults,
    backgroundColor: colorsV2.gray10,
    height: 46,
    border: 'none',
    borderRadius: 8
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 20,
    color: colorsV2.black100
  })
};
const BaseSelect = props => {
  return <ReactSelect isSearchable={true} styles={baseStyles} {...props} />;
};
export const SelectCity = ({ onSelect = () => {}, ...props }) => {
  const { isLoading, data = [] } = useQuery('FETCH_CITY', fetchCity, {
    refetchOnWindowFocus: false,
    refetchOnMount: true
  });

  return (
    <BaseSelect
      isLoading={isLoading}
      placeholder="Thành Phố / Tỉnh"
      options={data.map(item => ({
        ...item,
        label: item.name,
        value: item.id
      }))}
      onChange={onSelect}
      {...props}
    />
  );
};
export const SelectDistrict = ({ onSelect = () => {}, cityId, ...props }) => {
  const { isLoading, data = [] } = useQuery(
    ['FETCH_DISTRICT', cityId],
    fetchDistricts(cityId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!cityId
    }
  );

  return (
    <BaseSelect
      isLoading={isLoading}
      isDisabled={!cityId}
      placeholder="Quận / Huyện"
      options={data.map(item => ({
        ...item,
        label: item.name,
        value: item.id
      }))}
      onChange={onSelect}
      {...props}
    />
  );
};

export const SelectWard = ({ onSelect = () => {}, districtId, ...props }) => {
  const { isLoading, data = [] } = useQuery(
    ['FETCH_Ward', districtId],
    fetchWards(districtId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!districtId
    }
  );

  return (
    <BaseSelect
      isLoading={isLoading}
      isDisabled={!districtId}
      placeholder="Phường / Xã"
      options={data.map(item => ({
        ...item,
        label: item.name,
        value: item.id
      }))}
      onChange={onSelect}
      {...props}
    />
  );
};
