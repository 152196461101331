/* eslint-disable react/prop-types */
import * as React from 'react';
import { withMarketingSection } from '../withMarketingSection';
import { Typography } from '../DesignSystemV2/Typography';
import styled from 'styled-components';
import { ReadNowButton } from './Buttons';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';

const Description = styled.div``;
const BookDetailSectionContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  .youtube-inline-video {
    border-radius: 8px;
  }
  ${Description} {
    margin-top: 32px;
  }
`;
const benefitModels = [
  {
    icon: 'WcjPaup1RzGf5JTbw9Cl',
    title: 'Phương pháp Linear tư duy khác biệt',
    text: 'Linearthinking - Hệ Phương Pháp Học Tiếng Anh Tư Duy dành cho người Việt- được tạo ra bởi anh Lê Đình Lực và sự hiệu quả của phương pháp đã được chứng minh bằng rất nhiều điểm cao và reviews của nhiều thế hệ học viên tại DOL IELTS Đình Lực.'
  },
  {
    icon: 'Bl7MGOr8RMuOtX5uP2mj',
    title: 'Sách trình bày tư duy dễ hiểu',
    text: 'Sách được được nghiên cứu và trình bày dựa trên hành vi, trải nghiệm của học viên nên sẽ giúp đọc giả có thể dễ dàng đọc hiểu và có một trải nghiệm tuyệt vời với nội dung cùng với những hình ảnh được thiết kế đẹp mắt của Thông não IELTS Reading cùng Linear',
    showReadNow: true
  },
  {
    icon: 'jLnVAPrgQ7K5lTMUnrRc',
    title: '5663+ học viên đã thành công nhờ Linear',
    text: 'Linearthinking - Hệ Phương Pháp Học Tiếng Anh Tư Duy dành cho người Việt- được tạo ra bởi anh Lê Đình Lực và sự hiệu quả của phương pháp đã được chứng minh bằng rất nhiều điểm cao và reviews của nhiều thế hệ học viên tại DOL IELTS Đình Lực.'
  },
  {
    icon: 'gMohaqkAQ5ik0vcjiK23',
    title: 'Linear được chứng nhận sở hữu trí tuệ',
    text: 'Linearthinking sau nhiều năm nghiên cứu và áp dụng thành công nay đã nhận được chứng nhận của Cục Sở Hữu Trí Tuệ'
  }
];
const Benefits = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: grid;
  grid-row-gap: 80px;
`;
const ReadNowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;
const Benefit = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 24px;

  @media ${deviceBreakpoints.md} {
    grid-auto-flow: column;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;

    :nth-child(even) {
      ${ReadNowWrapper} {
        justify-content: start;
      }
      img {
        grid-column: 2/3;
      }
    }
  }

  @media ${deviceBreakpoints.lg} {
    img {
      place-self: center;
    }
  }
`;

const BookDetail = () => (
  <BookDetailSectionContainer>
    <Typography
      variant="bold/32-40"
      tabletVariant="bold/40-48"
      style={{ textAlign: 'center' }}
    >
      Chi tiết về cuốn sách
    </Typography>
    <Description>
      <Benefits>
        {benefitModels.map(
          ({ title, text, icon, backgroundColor, showReadNow }, index) => (
            <Benefit key={index} style={{ backgroundColor: backgroundColor }}>
              <img
                src={getAssetLinkByHandle({ handle: icon })}
                width="auto"
                height="auto"
                alt="detail"
              />
              <div>
                <Typography variant="bold/24-32" tabletVariant="bold/32-40">
                  {title}
                </Typography>
                <Typography
                  variant="medium/16"
                  tabletVariant="medium/18-28"
                  style={{ marginTop: 12 }}
                >
                  {text}
                </Typography>
                {showReadNow && (
                  <ReadNowWrapper>
                    <ReadNowButton />
                  </ReadNowWrapper>
                )}
              </div>
            </Benefit>
          )
        )}
      </Benefits>
    </Description>
  </BookDetailSectionContainer>
);

export default withMarketingSection(BookDetail, {
  selfish: true,
  style: { paddingTop: 80 },
  id: 'chi-tiet'
});
