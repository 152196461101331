/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const YoutubeVideoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  ::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  &.ratio-4-3 {
    ::after {
      padding-top: 75%;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
export const YoutubeVideo = ({ src, ratio = '16-9', ...props }) => {
  return (
    <YoutubeVideoWrapper
      className={classNames([`ratio-${ratio}`, props.className])}
    >
      <iframe
        className="youtube-inline-video"
        title="YouTube video player"
        // width="100%"
        // height="auto"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        {...props}
      />
    </YoutubeVideoWrapper>
  );
};
