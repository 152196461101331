/* eslint-disable react/prop-types */
import * as React from 'react';
import { Typography } from '../DesignSystemV2/Typography';
import styled from 'styled-components';
import { Section, withMarketingSection } from '../withMarketingSection';
import { colorsV2 } from '../../style/colors-v2';
import {
  PromotionTimeCounter,
  TimerText,
  TimerUnit
} from './PromotionTimeCounter';
import MemoIconFilledCheckCircle from '../../shared/SchedulePage/icons/IconFilledCheckCircle';
import {
  FormErrorText,
  FormInput,
  FormInputNumber,
  FormItem,
  FormLabel,
  SelectCity,
  SelectDistrict,
  SelectWard
} from './Inputs';
import { useForm, Controller } from 'react-hook-form';
import { CTAButton } from './Buttons';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import axios from 'axios';
import { STUDENT_REGISTRATION_URL } from '../../apis/baseURL';
import { useMutation } from 'react-query';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { getUTMParams } from '../../utils/utm';

const BuyNowSectionContainer = styled.div`
  .title-with-pricing {
    display: grid;
    grid-row-gap: 32px;
    .countdown-number {
      .countdown-number-box {
        border: 2px solid ${colorsV2.white100};
        background-color: ${colorsV2.primary100};
        ${TimerText} {
          color: ${colorsV2.white100};
        }
      }
      .countdown-number-unit {
        ${TimerUnit} {
          color: ${colorsV2.white100};
        }
      }
    }
  }

  @media ${deviceBreakpoints.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    .title-with-pricing {
      place-content: flex-start;
    }
  }
`;

const DeliveryFormContainer = styled.div`
  padding: 16px;
  background-color: ${colorsV2.white100};
  border-radius: 16px;
  margin-top: 40px;

  @media ${deviceBreakpoints.md} {
    padding: 32px;
  }

  @media ${deviceBreakpoints.lg} {
    margin-top: 0;
  }
`;
const DeliveryFormWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  grid-row-gap: 16px;
  ${FormLabel} {
    margin-bottom: 4px;
  }
`;
const BuyNowButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const FormItemDistrictWard = styled(FormItem)`
  display: grid;
  grid-row-gap: 16px;

  @media ${deviceBreakpoints.md} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }
`;

const buyBookApi = data => {
  const {
    name,
    phoneNumber,
    email,
    address,
    amount,
    city: { name: cityName },
    district: { name: districtName },
    ward: { name: wardName },
    giftCode
  } = data;
  const utm = getUTMParams();
  return axios.put(STUDENT_REGISTRATION_URL, {
    name,
    phoneNumber,
    email,
    address,
    amount: Number(amount) || 1,
    city: cityName,
    district: districtName,
    ward: wardName,
    giftCode,
    type: 'BOOK_ORDER',
    sourceUrl: `${window.location.href}#${JSON.stringify(utm)}#`
  });
};
const SuccessMessageContainer = styled(DeliveryFormContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 285px;
  }
  .message {
    margin-top: 32px;
    ${CTAButton} {
      margin-left: auto;
      margin-right: auto;
      margin-top: 16px;
    }
  }
  p {
    text-align: center;
  }
`;
const Delivery = () => {
  const [isBuySuccess, setBuySuccess] = React.useState(false);

  if (isBuySuccess) {
    return (
      <SuccessMessageContainer>
        <img
          src={getAssetLinkByHandle({
            handle: 'kaSlYIKuQayZxDPZU9xd'
          })}
          alt="success"
        />
        <div className="message">
          <Typography variant="bold/24-32">Mua hàng thành công</Typography>
          <Typography
            variant="regular/14"
            tabletVariant="regular/16"
            color={{ color: colorsV2.black60 }}
          >
            Cảm ơn bạn đã tin tưởng và ủng hộ sản phẩm của DOL IELTS Đình Lực.
            Đội ngũ bán hàng sẽ gọi cho bạn nhanh nhất có thể.
          </Typography>
          <CTAButton onClick={() => setBuySuccess(false)}>
            Tiếp tục mua hàng
          </CTAButton>
        </div>
      </SuccessMessageContainer>
    );
  }

  return <DeliveryForm onSuccess={() => setBuySuccess(true)} />;
};

const DeliveryForm = ({ onSuccess }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
    control,
    watch,
    setValue
  } = useForm();
  const { isLoading, mutate } = useMutation(['BUY_BOOK'], buyBookApi, {
    onSuccess: () => onSuccess(true)
  });
  const city = watch('city') || {};
  const district = watch('district') || {};
  const ward = watch('ward') || {};

  if (
    district &&
    district.parentId &&
    city &&
    city.id &&
    district.parentId !== city.id
  ) {
    setValue('district', null);
    setValue('ward', null);
  }
  if (
    ward &&
    ward.parentId &&
    district &&
    district.id &&
    ward.parentId !== district.id
  ) {
    setValue('ward', null);
  }

  return (
    <DeliveryFormContainer>
      <Typography variant="bold/24-32">Thông tin giao hàng</Typography>
      <DeliveryFormWrapper>
        <FormItem>
          <FormLabel>Số lượng</FormLabel>
          <FormInputNumber {...register('amount')} />
        </FormItem>
        <FormItem>
          <FormInput
            placeholder="Họ và Tên"
            {...register('name', { required: true })}
          />
          {errors.name && (
            <FormErrorText>Bạn hãy điền tên ớ đây nhé.</FormErrorText>
          )}
        </FormItem>
        <FormItem>
          <FormInput
            placeholder="Số điện thoại"
            {...register('phoneNumber', {
              required: true,
              pattern: /^\d{10,11}$/g
            })}
          />
          {errors.phoneNumber && errors.phoneNumber.type === 'required' && (
            <FormErrorText>Bạn hãy điền SĐT ớ đây nhé.</FormErrorText>
          )}
          {errors.phoneNumber && errors.phoneNumber.type === 'pattern' && (
            <FormErrorText>SĐT không đúng format.</FormErrorText>
          )}
        </FormItem>
        <FormItem>
          <FormInput
            placeholder="Email"
            {...register('email', {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
            })}
          />
          {errors.email && errors.email.type === 'required' && (
            <FormErrorText>Bạn hãy điền email ớ đây nhé.</FormErrorText>
          )}
          {errors.email && errors.email.type === 'pattern' && (
            <FormErrorText>Email không đúng format.</FormErrorText>
          )}
        </FormItem>
        <FormItem>
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <SelectCity {...field} />}
          />
          {errors.city && errors.city.type === 'required' && (
            <FormErrorText>
              Bạn hãy chọn tỉnh/thành phố ớ đây nhé.
            </FormErrorText>
          )}
        </FormItem>
        <FormItemDistrictWard>
          <FormItem>
            <Controller
              name="district"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectDistrict cityId={city.id} {...field} />
              )}
            />
            {errors.district && errors.district.type === 'required' && (
              <FormErrorText>Bạn hãy chọn quận/huyện ớ đây nhé.</FormErrorText>
            )}
          </FormItem>
          <FormItem>
            <Controller
              name="ward"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectWard districtId={district.id} {...field} />
              )}
            />
            {errors.district && errors.district.type === 'required' && (
              <FormErrorText>Bạn hãy chọn phường/xã ớ đây nhé.</FormErrorText>
            )}
          </FormItem>
        </FormItemDistrictWard>

        <FormItem>
          <FormInput
            placeholder="Địa chỉ cụ thể"
            {...register('address', {
              required: true
            })}
          />
          {errors.address && errors.address.type === 'required' && (
            <FormErrorText>Bạn hãy điền địa chỉ ớ đây nhé.</FormErrorText>
          )}
        </FormItem>

        <FormItem>
          <FormInput
            placeholder="Gift Code (Optional)"
            {...register('giftCode')}
          />
        </FormItem>

        <BuyNowButtonWrapper>
          <CTAButton
            disabled={isLoading}
            onClick={async () => {
              await trigger();
              handleSubmit(mutate)();
            }}
          >
            Buy now
          </CTAButton>
        </BuyNowButtonWrapper>
      </DeliveryFormWrapper>
    </DeliveryFormContainer>
  );
};

// Benefits
const benefitModels = [
  'Miễn phí giao hàng toàn quốc',
  'Không hiệu quả, hoàn tiền',
  'Phương pháp được công nhận sở hữu trí tuệ',
  'Kèm theo web Luyện tập Online'
];
const Benefits = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;
const BenefitText = styled(Typography).attrs({
  variant: 'bold/14-20',
  tabletVariant: 'bold/18-28'
})``;
const Benefit = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 12px;
  ${BenefitText} {
    color: ${colorsV2.white100};
  }
`;

const BuyNowSectionBase = () => {
  return (
    <BuyNowSectionContainer>
      <div className="title-with-pricing">
        <div className="title">
          <Typography
            variant="bold/32-40"
            tabletVariant="bold/40-48"
            style={{ color: colorsV2.white100 }}
          >
            Thông não IELTS Reading cùng Linear
          </Typography>
        </div>
        <div className="pricing">
          <Typography
            variant="medium/16-24"
            tabletVariant="medium/20-28"
            style={{
              color: colorsV2.white60,
              'text-decoration': 'line-through'
            }}
          >
            499.000đ
          </Typography>
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            style={{ color: colorsV2.yellow100 }}
          >
            399.000đ
          </Typography>
        </div>
        <div className="promotion-counter">
          <PromotionTimeCounter />
        </div>
        <Benefits>
          {benefitModels.map((text, index) => (
            <Benefit key={index}>
              <MemoIconFilledCheckCircle width="22px" height="22px" />
              <BenefitText>{text}</BenefitText>
            </Benefit>
          ))}
        </Benefits>
      </div>
      <Delivery />
    </BuyNowSectionContainer>
  );
};
const BuyNowSection = withMarketingSection(BuyNowSectionBase, {
  selfish: true,
  style: {
    paddingTop: 40,
    backgroundColor: colorsV2.black80,
    overflow: 'visible'
  }
});

const BuyNowSectionSectionWrapper = styled.div`
  padding-top: 40px;
  @media ${deviceBreakpoints.lg} {
    ${Section} {
      max-width: 1120px;
      padding: 40px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 16px;
    }
  }
`;

const BuyNowSectionSection = () => {
  return (
    <BuyNowSectionSectionWrapper id="buy-now">
      <BuyNowSection />
    </BuyNowSectionSectionWrapper>
  );
};

export default BuyNowSectionSection;
