/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';
import QnAExpandCollapsePanel from './QnAExpandCollapse';
import { withMarketingSection } from '../withMarketingSection';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';

const QnAQuestionsWrapper = styled.div``;
const QnAQuestion = styled.div``;

const qnaData = [
  {
    question: 'Tại sao sách đắt thế?',
    answer:
      'Sách Thông não IELTS Reading cùng Linear được in màu trên chất liệu giấy dày, khổ B, dày gần 300 trang. Đồng thời nội dung sách với Linearthinking độc quyền được bảo hộ bởi Cục Sở Hữu Trí Tuệ. Do đó sách không chỉ tốt về mặt hình thức mà còn chất lượng về nội dung. Vì vậy với mức giá hiện tại là không hề vô lý.'
  },
  {
    question: 'Sách có chứng nhận gì không?',
    answer:
      'Sách được viết bởi 2 tác giả Lê Đình Lực - Founder tại DOL IELTS Đình Lực và Hà Đặng Như Quỳnh - IELTS Director tại DOL IELTS Đình Lực, là những giáo viên luyện thi IELTS hàng đầu tại TPHCM và đồng thời cũng là tác giả của Linearthinking - Hệ phương pháp học Tiếng Anh Từ duy dành cho người Việt được công nhận bởi Cục Sở Hữu Trí Tuệ và kết quả IELTS của hàng ngàn học viên'
  },
  {
    question: 'Sách phù hợp với level nào?',
    answer:
      'Cuốn sách Thông não IELTS Reading phù hợp với mọi đối tượng người đọc từ những bạn còn yếu đang tìm phương pháp đúng đắn ngay từ đầu; những bạn đang mắc kẹt không lên band do áp dụng Scan/Skim hay Đọc dịch không hiệu quả đến những bạn đã đạt điểm IELTS Reading cao 8.0, 9.0 muốn tiếp tục phát triển khả năng đọc tiếng Anh của mình.\n' +
      'Ngoài ra, Linearthinking không chỉ áp dụng trong IELTS mà còn áp dụng trong đọc hiểu các văn bản tiếng Anh nói chung từ những bài thi Tiếng Anh khác như TOEIC, TOEFL, SAT, GMAT,... đến các văn bản học thuật, chuyên ngành.'
  },
  {
    question: 'Có thể áp dụng vào thi được không?',
    answer:
      'Hoàn toàn có. Linearthinking không chỉ áp dụng trong IELTS mà còn áp dụng trong đọc hiểu các văn bản tiếng Anh nói chung từ những bài thi Tiếng Anh khác như TOEIC, TOEFL, SAT, GMAT,... đến các văn bản học thuật, chuyên ngành.'
  },
  {
    question: 'Linear có thể áp dụng vào các kỹ năng khác không?',
    answer:
      'Linearthinking là 1 hệ phương pháp học Tiếng Anh Tư duy dành cho người Việt. Do đó, với mỗi kỹ năng và trình độ khác nhau, Linear có cách tiếp cận và phương pháp riêng biệt giúp học viên phát triển toàn diện, nhanh chóng và phù hợp nhất\n' +
      'Trong thời gian tới, DOL IELTS Đình Lực sẽ xuất bản các đầu sách tiếp theo về Linearthinking trong các kỹ năng khác bao gồm: Writing, Speaking, Listening, Vocabulary & Grammar.'
  }
];
const QnAQuestions = () => {
  const [expandIndex, setExpandIndex] = React.useState();

  return (
    <QnAQuestionsWrapper>
      {qnaData.map((qnaData, index) => (
        <QnAQuestion key={index}>
          <QnAExpandCollapsePanel
            onExpandCollapseClick={() => {
              setExpandIndex(prevExpandIndex => {
                if (prevExpandIndex === index) return -1;
                return index;
              });
            }}
            expanded={expandIndex === index}
            title={qnaData.question}
          >
            <Typography
              variant="regular/18-28"
              style={{ color: colorsV2.white80 }}
            >
              {qnaData.answer}
            </Typography>
          </QnAExpandCollapsePanel>
        </QnAQuestion>
      ))}
    </QnAQuestionsWrapper>
  );
};

const QnAContainer = styled.div`
  .title {
    border-bottom: 1px solid ${colorsV2.black40};
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  @media ${deviceBreakpoints.md} {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 16px;

    .title {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;
const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${colorsV2.black60};
`;
const QnA = () => {
  return (
    <QnAContainer>
      <div className="title">
        <Typography
          variant="bold/32-40"
          tabletVariant="bold/40-48"
          style={{ color: colorsV2.white100 }}
        >
          Q&As
        </Typography>
        <Typography
          variant="medium/18-28"
          style={{ color: colorsV2.white80, marginTop: 12 }}
        >
          Những thắc mắc thường gặp về cuốn sách Thông não IELTS Reading cùng
          Linear
        </Typography>
      </div>
      <div className="tablet-only desktop-only">
        <Divider />
      </div>
      <QnAQuestions />
    </QnAContainer>
  );
};

export default withMarketingSection(QnA, {
  selfish: true,
  style: { paddingTop: 80, backgroundColor: colorsV2.black100 },
  id: 'qna'
});
