import styled, { css } from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { Typography } from '../DesignSystemV2/Typography';
import * as React from 'react';
import { Link } from 'gatsby';
import { useBookPreviewModal } from './BookPreviewModal';

const BaseButton = styled.button`
  width: 184px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const PrimaryButton = styled(BaseButton)`
  background-color: ${colorsV2.primary100};
  border: none;

  ${props =>
    props.boxShadow &&
    css`
      box-shadow: 0px 18px 40px -12px rgba(209, 66, 66, 0.6);
    `}
`;
const PrimaryButtonHeader = styled(PrimaryButton)`
  width: 98px;
  height: 38px;
`;
const OutlineButton = styled(BaseButton)`
  width: 123px;
  border: 2px solid ${colorsV2.black100};
  background-color: transparent;
`;
export const BuyNowHeaderButton = () => (
  <Link to="#buy-now">
    <PrimaryButtonHeader>
      <Typography
        type="span"
        variant="semi-bold/14-24"
        style={{ color: colorsV2.white100 }}
      >
        Mua ngay
      </Typography>
    </PrimaryButtonHeader>
  </Link>
);
const CTAButtonBase = ({ children, ...props }) => (
  <PrimaryButton boxShadow {...props}>
    <Typography
      type="span"
      variant="semi-bold/14-24"
      style={{ color: colorsV2.white100 }}
    >
      {children}
    </Typography>
  </PrimaryButton>
);
export const CTAButton = styled(CTAButtonBase)``;

const BaseBuyNowButton = props => (
  <Link to="#buy-now">
    <CTAButton {...props}>Mua ngay</CTAButton>
  </Link>
);
export const BuyNowButton = styled(BaseBuyNowButton)``;
export const ReadNowButton = () => {
  const { setShowModal } = useBookPreviewModal();
  return (
    <OutlineButton>
      <Typography
        type="span"
        variant="semi-bold/14-24"
        style={{ color: colorsV2.black100 }}
        onClick={() => setShowModal(true)}
      >
        Đọc thử ngay
      </Typography>
    </OutlineButton>
  );
};
