/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../DesignSystemV2/Typography';
import { YoutubeVideo } from '../../shared/Youtube/YoutubeVideo';
import { colorsV2 } from '../../style/colors-v2';
import { DeferredImage } from './BookUserReviewCard';
import { getWindow } from 'ssr-window';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import { BuyNowButton } from './Buttons';
import { graphql, useStaticQuery } from 'gatsby';

const ActiveVideoWrapper = styled.div`
  .title {
    padding: 16px;
  }

  @media ${deviceBreakpoints.md} {
    .title {
      padding: 0;
      padding-top: 16px;
    }
  }
`;
const ActiveVideo = ({ title, url }) => {
  return (
    <ActiveVideoWrapper>
      <YoutubeVideo src={url} ratio="4-3" />
      <div className="title">
        <Typography variant="bold/16-24" tabletVariant="bold/20-28">
          {title}
        </Typography>
        <Typography
          variant="medium/14-20"
          style={{ color: colorsV2.black80, marginTop: 8 }}
        >
          DOL IELTS Đình Lực
        </Typography>
      </div>
    </ActiveVideoWrapper>
  );
};
const Video = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  padding: 16px 0;
  grid-column-gap: 16px;
  border-bottom: 1px solid ${colorsV2.gray10};
  :hover {
    cursor: pointer;
  }
  .video-image {
    position: relative;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    img {
      border-radius: 8px;
    }
    .video-length {
      position: absolute;
      bottom: 5px;
      right: 0;
      background: ${colorsV2.black80};
      border-radius: 4px;
      padding: 2px 5px;
    }
  }
`;
const Videos = styled.div``;
const OtherVideosWrapper = styled.div`
  padding: 0 16px;
  ${Videos} {
    margin-top: 24px;
  }

  @media ${deviceBreakpoints.md} {
    padding: 0;
  }

  @media ${deviceBreakpoints.lg} {
    ${Videos} {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
`;
const OtherVideos = ({ videos, onVideoSelected }) => {
  return (
    <OtherVideosWrapper>
      <Typography variant="bold/20-28">CÁC VIDEO KHÁC</Typography>
      <Videos>
        {videos.map(({ id, thumbnail, length, title }) => (
          <Video key={id} onClick={() => onVideoSelected(id)}>
            <div className="video-image">
              <DeferredImage handle={thumbnail} alt={title} />
              <div className="video-length">
                <Typography
                  type="span"
                  variant="medium/14-20"
                  style={{ color: colorsV2.white100 }}
                >
                  {length}
                </Typography>
              </div>
            </div>
            <div className="video-info">
              <Typography variant="bold/16-24">{title}</Typography>
              <Typography
                variant="medium/14-20"
                style={{ color: colorsV2.black80 }}
              >
                DOL IELTS Đình Lực
              </Typography>
            </div>
          </Video>
        ))}
      </Videos>
    </OtherVideosWrapper>
  );
};
const videoDataQuery = graphql`
  query VideoDataQuery {
    gcms {
      jsonContaniers(where: { name: "Landing_Book_Reader_Feedback_Video" }) {
        name
        content
      }
    }
  }
`;
const useVideoData = () => {
  const data = useStaticQuery(videoDataQuery);
  return data.gcms.jsonContaniers[0].content;
};

const VideoListWrapper = styled.div`
  max-width: 425px;
  margin-left: auto;
  margin-right: auto;
  ${OtherVideosWrapper} {
    margin-top: 16px;
  }

  @media ${deviceBreakpoints.md} {
    max-width: 524px;
  }

  @media ${deviceBreakpoints.lg} {
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    max-width: 1120px;

    ${OtherVideosWrapper} {
      margin-top: 0;
    }
  }
`;
const VideoList = () => {
  const scrollRef = React.useRef();
  const videoData = useVideoData();
  const [selectedVideo, setSelectedVideo] = React.useState(videoData[0]);
  const otherVideos = videoData.filter(video => video.id !== selectedVideo.id);
  const window = getWindow();

  const selectActiveVideo = React.useCallback(
    id => {
      setSelectedVideo(videoData.find(video => video.id === id));
      window.scrollTo({
        top:
          window.pageYOffset +
          scrollRef.current.getBoundingClientRect().top -
          120,
        behavior: 'smooth'
      });
    },
    [videoData, window]
  );
  return (
    <VideoListWrapper ref={scrollRef}>
      <ActiveVideo {...selectedVideo} />
      <OtherVideos videos={otherVideos} onVideoSelected={selectActiveVideo} />
    </VideoListWrapper>
  );
};
const ReaderFeedbackContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;

  ${VideoListWrapper} {
    margin-top: 52px;
  }

  ${BuyNowButton} {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${deviceBreakpoints.md} {
    margin-bottom: 80px;
  }
`;
export const ReaderFeedback = () => (
  <ReaderFeedbackContainer>
    <Typography
      variant="bold/32-40"
      tabletVariant="bold/40-48"
      style={{ textAlign: 'center', padding: '0 16px' }}
    >
      Người nổi tiếng nói gì về cuốn sách
    </Typography>
    <VideoList />
    <BuyNowButton />
  </ReaderFeedbackContainer>
);
