import * as React from 'react';

import MainLayout from 'components/Layout/MainLayout';
import MySEO from 'shared/MySEO';
import HeaderLandingDolBook from '../components/LandingDOLBook/HeaderLandingDolBook';
import LandingBookPageBanner from '../components/LandingDOLBook/LandingBookPageBanner';
import ThisBookForWhoSection from '../components/LandingDOLBook/ThisBookForWhoSection';
import BookSpecialPointSection from '../components/LandingDOLBook/BookSpecialPoints';
import BookDetail from '../components/LandingDOLBook/BookDetail';
import BookReview from '../components/LandingDOLBook/BookReview';
import { ReaderFeedback } from '../components/LandingDOLBook/ReaderFeedback';
import QnA from '../components/LandingDOLBook/QnA';
import BuyNowSection from '../components/LandingDOLBook/BuyNowSection';
import {
  BookPreviewModal,
  BookPreviewModalContext
} from '../components/LandingDOLBook/BookPreviewModal';
import { useBookData } from '../shared/LandingBookSelling/useBookData';
import { getAssetLinkByHandle } from '../utils/getAssetLink';
import MainFooter from '../components/LandingDOLBook/Footer';

const StudentCoursePage = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { previewBookPdfHandle } = useBookData();
  return (
    <BookPreviewModalContext.Provider value={{ showModal, setShowModal }}>
      <MainLayout
        renderCustomHeader={() => <HeaderLandingDolBook />}
        noHeader
        hideSideCTA
        noFooter
        showFooterRegister={false}
      >
        <MySEO
          title="Lấy IELTS 9.0 Reading một cách thông mình cùng Linearthinking"
          description="Thông não IELTS Reading cùng Linearthinking"
          featureImg={{ handle: 'EzjDfEmdQmaudb9cPkHK' }}
        >
          <link
            rel="preload"
            as="image"
            href={getAssetLinkByHandle({
              handle: 'ZeJi4HKrTkCVHXifVtqW'
            })}
          />
        </MySEO>
        <LandingBookPageBanner />
        <ThisBookForWhoSection />
        <BookSpecialPointSection />
        <BookReview />
        <BookDetail />
        <BuyNowSection />
        <ReaderFeedback />
        <QnA />
        <BookPreviewModal previewBookPdfHandle={previewBookPdfHandle} />
        <MainFooter />
      </MainLayout>
    </BookPreviewModalContext.Provider>
  );
};

export default StudentCoursePage;
