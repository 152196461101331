/* eslint-disable react/prop-types */
import * as React from 'react';
import { withMarketingSection } from '../withMarketingSection';
import { Typography } from '../DesignSystemV2/Typography';
import { YoutubeVideo } from '../../shared/Youtube/YoutubeVideo';
import styled from 'styled-components';
import MemoIconFilledCheckCircle from '../../shared/SchedulePage/icons/IconFilledCheckCircle';
import { BuyNowButton } from './Buttons';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
const Description = styled.div``;
const ThisBookForWhoSectionContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  .youtube-inline-video {
    border-radius: 8px;
  }
  ${Description} {
    margin-top: 32px;
  }

  @media ${deviceBreakpoints.md} {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${deviceBreakpoints.lg} {
    max-width: 712px;
  }
`;
const benefitModels = [
  'Thí sinh IELTS, đang và chuẩn bị ôn luyện cho kỳ thi IELTS, muốn nhanh lấy điểm Reading cao, thậm chí là 9.0.',
  'Những bạn đã thi xong IELTS, điểm Reading cao, nhưng vẫn gặp vấn đề về đọc hiểu và tóm ý đoạn văn sau 1 lần đọc.',
  'Những bạn muốn điểm cao Reading các chương trình TOEIC, SAT, TOEFL, GMAT, GRE.',
  'Những bạn học Tiếng Anh nói chung, muốn đọc báo, đọc sách Tiếng Anh dễ hiểu không cần tra từng từ vựng, đọc đến đâu hiểu và nhớ đến đó.',
  'Những bạn thấy bế tắc với phương pháp đọc dịch, hay SCAN SKIM.'
];
const Benefits = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: grid;
  grid-row-gap: 8px;
`;
const Benefit = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 12px;
`;
const BuyNowButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ThisBookForWhoSection = () => (
  <ThisBookForWhoSectionContainer>
    <Typography
      variant="bold/32-40"
      tabletVariant="bold/40-48"
      style={{ textAlign: 'center' }}
    >
      Cuốn sách này dành cho ai?
    </Typography>
    <Description>
      <YoutubeVideo src="https://www.youtube.com/embed/vKIjHe4NaFs" />
      <Benefits>
        {benefitModels.map((text, index) => (
          <Benefit key={index}>
            <MemoIconFilledCheckCircle width="22px" height="22px" />
            <Typography variant="medium/14-20" tabletVariant="medium/18-28">
              {text}
            </Typography>
          </Benefit>
        ))}
      </Benefits>
      <BuyNowButtonWrapper>
        <BuyNowButton />
      </BuyNowButtonWrapper>
    </Description>
  </ThisBookForWhoSectionContainer>
);

export default withMarketingSection(ThisBookForWhoSection, {
  selfish: true,
  style: { paddingTop: 80 },
  id: 'gioi-thieu'
});
