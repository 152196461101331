import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import Stars from 'react-star-ratings';

import styled from 'styled-components';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

const Main = styled.div`
  &.card-user-review {
    background: ${colorsV2.white100};
    padding: 24px;
    border-radius: 16px;
    display: grid;
    gap: 16px;
    .header {
      .header-wrapper {
        display: grid;
        grid-template-columns: 48px 1fr;
        gap: 12px;
        grid-auto-flow: column;
        align-items: center;
        .avatar {
          min-width: 48px;
          min-height: 48px;
          border-radius: 50%;
          width: 48px;
          height: 48px;
          overflow: hidden;
          background-color: ${colorsV2.gray40};
        }
      }
    }
    .content {
      display: grid;
      gap: 16px;
    }
    .rating {
      display: grid;
      gap: 12px;
      grid-auto-flow: column;
      align-items: center;
      grid-template-columns: max-content;
      .star-ratings {
        line-height: 0;
      }
    }
    .comment {
      .comment-images {
        display: block;
        .comment-image {
          display: inline-block;
          margin-right: 16px;
          margin-top: 16px;
          width: 80px;
          height: 80px;
          cursor: pointer;
          transition: all 0.2s ease;
          &:active {
            transform: scale(0.98);
          }
          > div {
            position: relative;
            padding-top: 100%;
            width: 100%;
            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;

              height: 100%;
              object-fit: cover;
              border-radius: 8px;
            }
          }
        }
      }
    }
    .created-at {
    }
  }

  @media ${deviceBreakpoints.md} {
    &.card-user-review {
      .header {
        .header-wrapper {
          grid-template-columns: 63px 1fr auto;
          .avatar {
            min-width: 64px;
            min-height: 64px;
            width: 64px;
            height: 64px;
          }
        }
      }
    }
  }
`;

export const DeferredImage = ({ alt, handle, ...props }) => {
  const [curHandle, setHandle] = React.useState();
  React.useEffect(() => {
    setHandle(null);
    setTimeout(() => {
      setHandle(handle);
    }, 500);
  }, [handle]);
  if (!curHandle) {
    return null;
  }
  return <LazyImage alt={alt} handle={curHandle} {...props} />;
};

const BookUserReviewCard = ({ data = {} }) => {
  const {
    // id,
    // order,
    reviewImageHandle,
    date,
    avaHandle,
    name,
    rating,
    reviewText
  } = data;
  const [isOpen, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(-1);
  const reviewImages = reviewImageHandle ? reviewImageHandle.split('\n') : [];

  return (
    <Main className="card-user-review">
      <div className="header">
        <div className="header-wrapper">
          <div className="avatar">
            <LazyImage alt={name} handle={avaHandle} />
          </div>
          <div className="header-content">
            <Typography variant="semi-bold/14">{name}</Typography>
            <div className="rating">
              <Stars
                rating={Number(rating) || 5}
                starRatedColor={colorsV2.yellow100}
                starDimension="18px"
                starSpacing="2.4px"
                numberOfStars={5}
                svgIconViewBox="0 0 16 15"
                svgIconPath="M7.10986 0.801073C7.4814 0.075375 8.51855 0.075375 8.8901 0.801073L10.4602 3.86784C10.6087 4.15787 10.8892 4.35737 11.2119 4.40249L14.6595 4.88449C15.4912 5.00076 15.8178 6.02838 15.2059 6.60352L12.7582 8.90411C12.512 9.13548 12.3993 9.4755 12.4586 9.80809L13.0429 13.0897C13.1875 13.9017 12.3424 14.5298 11.6066 14.1571L8.4518 12.5593C8.16776 12.4155 7.8322 12.4155 7.54816 12.5593L4.39335 14.1571C3.65758 14.5298 2.81243 13.9017 2.95702 13.0897L3.5414 9.80809C3.60063 9.4755 3.48793 9.13548 3.24177 8.90411L0.794072 6.60352C0.182161 6.02838 0.508801 5.00076 1.34048 4.88449L4.78807 4.40249C5.11076 4.35737 5.39123 4.15787 5.53972 3.86784L7.10986 0.801073Z"
                name="rating"
              />
            </div>
          </div>
          <div className="created-at">
            <Typography
              style={{ color: colorsV2.gray100 }}
              variant="regular/14"
            >
              {date}
            </Typography>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="comment">
          <Typography variant="regular/16">{reviewText}</Typography>
          <div className="comment-images">
            {reviewImages.map((imageHandle, index) => {
              return (
                <div
                  onClick={() => {
                    setIndex(index);
                    setOpen(true);
                  }}
                  key={index}
                  className="comment-image"
                >
                  <LazyImage
                    alt={name}
                    handle={imageHandle}
                    width={80}
                    height={80}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 200000
            }
          }}
          mainSrc={getAssetLinkByHandle({
            handle: reviewImages[index]
          })}
          nextSrc={getAssetLinkByHandle({
            handle: reviewImages[(index + 1) % reviewImages.length]
          })}
          prevSrc={getAssetLinkByHandle({
            handle:
              reviewImages[
                (index + reviewImages.length - 1) % reviewImages.length
              ]
          })}
          onCloseRequest={() => {
            setOpen(false);
            setIndex(-1);
          }}
          onMovePrevRequest={() =>
            setIndex((index + reviewImages.length - 1) % reviewImages.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % reviewImages.length)}
        />
      )}
    </Main>
  );
};

export default BookUserReviewCard;
