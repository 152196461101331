/* eslint-disable react/prop-types */
import * as React from 'react';
import Timer from 'react-compound-timer';
import { padStart } from 'lodash';
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { Typography } from '../DesignSystemV2/Typography';

const formatDuration = durationValue => padStart(durationValue, 2, '0');

export const TimerText = styled(Typography).attrs({
  variant: 'bold/16-24',
  type: 'span'
})``;
export const TimerUnit = styled(Typography).attrs({
  variant: 'medium/14-20',
  type: 'span'
})``;
const PromotionCountdownContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 7px;
  grid-auto-columns: min-content;
  .countdown-number {
    .countdown-number-box {
      padding: 12px;
      width: 52px;
      height: 52px;
      border: 2px solid ${colorsV2.primary100};
      border-radius: 8px;
      background-color: #fff;

      letter-spacing: 0px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      ${TimerText} {
        color: ${colorsV2.primary100};
      }
    }
    .countdown-number-unit {
      margin-top: 8px;
      text-align: center;
      ${TimerUnit} {
        color: ${colorsV2.black80};
      }
    }
  }
  .countdown-separator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }
  .row-center {
    display: flex;
    align-items: center;
  }
`;

const IconColon = () => (
  <Typography variant="medium/18-28" style={{ color: colorsV2.black80 }}>
    :
  </Typography>
);
export const PromotionTimeCounter = ({
  fromTime = '2021-07-14T00:00:00+07:00',
  repeatAfterMillis = 4 * 60 * 60 * 1000
}) => {
  // (current time - from time) % mod 2 days
  const curTime = new Date().getTime();
  const fromTimeDate = new Date(fromTime);
  const daysRange = Math.ceil(
    Math.max(curTime - fromTimeDate.getTime(), 0) / repeatAfterMillis
  );
  let initialTime =
    fromTimeDate.getTime() + daysRange * repeatAfterMillis - curTime;
  if (initialTime <= 0) {
    initialTime = repeatAfterMillis;
  }
  return (
    <Timer initialTime={initialTime} direction="backward">
      {() => (
        <PromotionCountdownContainer className="row-center countdown-wrapper">
          <div className="countdown-number">
            <div className="countdown-number-box">
              <TimerText>
                <Timer.Days formatValue={formatDuration} />
              </TimerText>
            </div>
            <div className="countdown-number-unit">
              <TimerUnit>Ngày</TimerUnit>
            </div>
          </div>
          <div className="countdown-separator">
            <IconColon />
          </div>
          <div className="countdown-number">
            <div className="countdown-number-box">
              <TimerText>
                <Timer.Hours formatValue={formatDuration} />
              </TimerText>
            </div>
            <div className="countdown-number-unit">
              <TimerUnit>Giờ</TimerUnit>
            </div>
          </div>
          <div className="countdown-separator">
            <IconColon />
          </div>
          <div className="countdown-number">
            <div className="countdown-number-box">
              <TimerText>
                <Timer.Minutes formatValue={formatDuration} />
              </TimerText>
            </div>
            <div className="countdown-number-unit">
              <TimerUnit>Phút</TimerUnit>
            </div>
          </div>
          <div className="countdown-separator">
            <IconColon />
          </div>
          <div className="countdown-number">
            <div className="countdown-number-box">
              <TimerText>
                <Timer.Seconds formatValue={formatDuration} />
              </TimerText>
            </div>
            <div className="countdown-number-unit">
              <TimerUnit>Giây</TimerUnit>
            </div>
          </div>
        </PromotionCountdownContainer>
      )}
    </Timer>
  );
};
