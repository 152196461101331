/* eslint-disable react/prop-types */
import * as React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Modal from '../../shared/CourseLanding/Modal';
import styled from 'styled-components';
import {
  deviceBreakpoints,
  useDeviceDetect
} from '../../utils/hooks/useDeviceQuery.hook';
import { getAssetLink } from '../../utils/getAssetLink';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PdfViewerContainer = styled.div`
  max-height: 85vh;
  width: calc(100vw - 48px);
  overflow: auto;
  @media ${deviceBreakpoints.md} {
    max-height: 80vh;
    width: calc(680px - 48px);
  }
  @media ${deviceBreakpoints.lg} {
    width: 1000px;
  }
`;
export const BookPreviewModalContext = React.createContext({
  showModal: false,
  setShowModal: () => {}
});
export const useBookPreviewModal = () =>
  React.useContext(BookPreviewModalContext);

export const BookPreviewModal = ({ previewBookPdfHandle }) => {
  const { showModal, setShowModal } = useBookPreviewModal();
  const documentWrapperRef = React.useRef();
  const pdfLink = `${getAssetLink({
    item: { handle: previewBookPdfHandle }
  })}`;
  const [numPages, setNumPages] = React.useState(null);
  const { isMobile, isTablet } = useDeviceDetect();
  const pdfPageWidth = React.useMemo(() => {
    if (isMobile) {
      return window.innerWidth - 48;
    }
    if (isTablet) {
      return 680 - 48;
    }
    return 1000;
  }, [isMobile, isTablet]);
  return (
    <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
      <PdfViewerContainer ref={documentWrapperRef}>
        <Document
          file={pdfLink}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={pdfPageWidth}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </PdfViewerContainer>
    </Modal>
  );
};
