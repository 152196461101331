import * as React from 'react';

import cl from 'classnames';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { Typography } from '../DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';

/* eslint-disable react/prop-types */
const Main = styled.div`
  padding: 16px 0;
  &.expand-collapse-panel {
    &:not(:first-child) {
      border-top: 1px solid ${colorsV2.gray10}66;
    }
    border-bottom: 1px solid ${colorsV2.gray10}66;
  }
  .panel-header {
    display: flex;
    cursor: pointer;
    svg {
      color: ${colorsV2.white100};
      font-size: 24px;
    }
    .panel-header-title {
      flex: 1;
    }
    .panel-header-icon {
      line-height: 0;
      display: flex;
      align-items: center;
    }
  }
  .panel-content {
    margin-top: 12px;
  }
`;
const PanelHeaderTitle = styled.div`
  display: grid;
  grid-auto-columns: 24px auto;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 12px;
`;

const ExpandCollapseVariants = {
  expanded: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0, overflow: 'hidden' }
};

const RotateChevronVariants = {
  expanded: { rotate: 45 },
  collapsed: { rotate: 0 }
};

const QnAExpandCollapsePanel = ({
  expanded = false,
  onExpandCollapseClick,
  title,
  titleProps,
  children,
  ...rest
}) => {
  return (
    <Main className={cl('expand-collapse-panel', { expanded: expanded })}>
      <div onClick={onExpandCollapseClick} className="panel-header">
        <PanelHeaderTitle className="panel-header-title" {...titleProps}>
          <img
            src={getAssetLinkByHandle({ handle: '4v8exJw4SEy2p4osWtBQ' })}
            alt="question"
            width={24}
            height={24}
          />
          <div className="panel-title">
            <Typography
              variant="bold/20-28"
              type="span"
              style={{ color: colorsV2.white100 }}
            >
              {title}
            </Typography>{' '}
          </div>
        </PanelHeaderTitle>
        <div className="panel-header-icon">
          <motion.svg
            initial={'collapsed'}
            animate={expanded ? 'expanded' : 'collapsed'}
            variants={RotateChevronVariants}
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
              fill="#fff"
            />
          </motion.svg>
        </div>
      </div>

      <motion.div
        className="panel-content"
        initial={'collapsed'}
        variants={ExpandCollapseVariants}
        animate={expanded ? 'expanded' : 'collapsed'}
        transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
        {...rest}
      >
        {children}
      </motion.div>
    </Main>
  );
};

export default QnAExpandCollapsePanel;
