/* eslint-disable react/prop-types */
import * as React from 'react';
import { withMarketingSection } from '../withMarketingSection';
import styled from 'styled-components';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import ProgressiveImage from 'react-progressive-image';
import { Typography } from '../DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';
import { PromotionTimeCounter } from './PromotionTimeCounter';
import { BuyNowButton, ReadNowButton } from './Buttons';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';

const LandingBookPageBannerContainer = styled.div`
  display: grid;
  grid-row-gap: 24px;
  .section-title-with-banner {
    display: grid;
  }
  .title-section {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 24px;
    align-items: center;
    p {
      text-align: center;
    }
    .flash-sale {
      display: grid;
      grid-column-gap: 8px;
      grid-template-columns: 20px auto;
      justify-content: center;
    }
    .banner-title {
      position: relative;
      text-align: center;
    }
    .rectangle {
      position: absolute;
      z-index: -1;
      width: 229px;
      height: 17px;
      background-color: ${colorsV2.primary60};
      top: 41px;
      left: 58px;

      @media (min-width: 390px) and (max-width: 765px) {
        left: 19px;
      }
      @media ${deviceBreakpoints.md} {
        width: 317px;
        top: 58px;
        left: 113px;
      }
      @media ${deviceBreakpoints.lg} {
        left: 0;
      }
    }
  }
  .pricing {
    justify-content: center;
    display: flex;
    > div {
      text-align: center;
    }
  }
  .promotion-counter {
    justify-content: center;
    display: flex;
  }
  .actions {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-column-gap: 16px;
    justify-content: center;
  }

  @media ${deviceBreakpoints.md} {
    .banner-image {
      max-width: 533px;
      margin: 0 auto;
    }
  }

  @media ${deviceBreakpoints.lg} {
    .section-title-with-banner {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 5fr 7fr;
      grid-column-gap: 32px;
      margin-right: -80px;
    }

    .banner-image {
      max-width: 100%;
      width: 681px;
      margin-top: -80px;
    }

    .title-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .flash-sale {
        justify-content: flex-start;
      }
      .banner-title {
        text-align: start;
      }
      p {
        text-align: start;
      }
    }
  }
`;

const LandingBookPageBannerSectionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .left-gradient {
    position: absolute;
    top: 63px;
    width: 100%;
    left: -461px;
    min-width: 896px;
  }
  .top-gradient {
    position: absolute;
    top: -700px;
    width: 100%;
    right: -800px;
    min-width: 1203px;
  }

  @media ${deviceBreakpoints.lg} {
    .left-gradient {
      top: 54px;
      left: -400px;
      max-width: 900px;
    }
  }
`;
const LandingBookPageBannerSection = () => {
  return (
    <LandingBookPageBannerSectionWrapper>
      <ProgressiveImage
        src={getAssetLinkByHandle({
          handle: 'aYGTUVyMTCOQLvshjAoC'
        })}
        placeholder={getAssetLinkByHandle({
          handle: 'aYGTUVyMTCOQLvshjAoC',
          width: 10
        })}
      >
        {src => (
          <img
            className="left-gradient"
            src={src}
            width="auto"
            height="auto"
            alt="left-gradient-banner"
          />
        )}
      </ProgressiveImage>

      <ProgressiveImage
        src={getAssetLinkByHandle({
          handle: 'JkwwKVIQhWeqAiDo6tVA'
        })}
        placeholder={getAssetLinkByHandle({
          handle: 'JkwwKVIQhWeqAiDo6tVA',
          width: 10
        })}
      >
        {src => (
          <img
            className="top-gradient"
            src={src}
            width="auto"
            height="auto"
            alt="top-gradient-banner"
          />
        )}
      </ProgressiveImage>
      <LandingBookPageBanner />
    </LandingBookPageBannerSectionWrapper>
  );
};
const benefitsModel = [
  {
    icon: 'vIZLEtH5QR6bFqYDJSoL',
    text: 'Miễn phí giao hàng toàn quốc'
  },
  {
    icon: 'opIB0AzKRjuXAtKC8VHI',
    text: 'Phương pháp được công nhận'
  },
  {
    icon: 'lfQ2cPFcSBeU8nEa3Y0p',
    text: 'Không hiệu quả, hoàn tiền'
  },
  {
    icon: 'IirSovoTDetUSfHOL2kc',
    text: 'Kèm theo web Luyện tập Online'
  }
];
const BenefitsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media ${deviceBreakpoints.md} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
const Benefit = styled.div`
  display: grid;
  grid-row-gap: 16px;
  border: 1px solid ${colorsV2.black10};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
`;
const BenefitIcon = styled.div`
  display: flex;
  justify-content: center;
  > div {
    border-radius: 12px;
    background: ${colorsV2.black5};
    padding: 6px;
    line-height: 0;
  }
`;
const Benefits = () => (
  <BenefitsWrapper>
    {benefitsModel.map(({ icon, text }, index) => (
      <Benefit key={index}>
        <BenefitIcon>
          <div>
            <img
              src={getAssetLinkByHandle({ handle: icon })}
              width={44}
              height={44}
              alt="icon benefit"
            />
          </div>
        </BenefitIcon>
        <Typography variant="semi-bold/16-24">{text}</Typography>
      </Benefit>
    ))}
  </BenefitsWrapper>
);

const LandingBookPageBannerBase = () => {
  return (
    <LandingBookPageBannerContainer>
      <div className="section-title-with-banner">
        <div className="title-section">
          <div className="section-title">
            <div className="flash-sale">
              <img
                src={getAssetLinkByHandle({ handle: 'vCtD1I6KQcaPicFA15Bh' })}
                width={20}
                height={20}
                alt="icon discount"
              />
              <Typography
                type="span"
                variant="bold/16"
                style={{ color: colorsV2.red100 }}
              >
                FLASH SALE
              </Typography>
            </div>
            <div className="banner-title">
              <Typography
                type="span"
                variant="black/44-66"
                desktopVariant="black/60-84"
                tabletVariant="black/60-84"
              >
                Thông não IELTS Reading cùng Linear
              </Typography>
              <div className="rectangle" />
            </div>
          </div>
          <div className="pricing">
            <div>
              <Typography
                variant="regular/16-24"
                tabletVariant="regular/20-28"
                style={{
                  color: colorsV2.black80,
                  'text-decoration': 'line-through'
                }}
              >
                499.000đ
              </Typography>
              <Typography
                variant="bold/24-32"
                tabletVariant="bold/32-40"
                style={{ color: colorsV2.red100 }}
              >
                399.000đ
              </Typography>
            </div>
          </div>

          <div className="promotion-counter">
            <PromotionTimeCounter />
          </div>

          <div className="actions">
            <BuyNowButton />
            <ReadNowButton />
          </div>
        </div>

        <img
          src={getAssetLinkByHandle({
            handle: 'ZeJi4HKrTkCVHXifVtqW'
          })}
          width="auto"
          height="auto"
          alt="home-banner"
          className="banner-image"
        />
      </div>

      <Benefits />
    </LandingBookPageBannerContainer>
  );
};

const LandingBookPageBanner = withMarketingSection(LandingBookPageBannerBase, {
  selfish: true,
  style: {
    paddingBottom: 0
  }
});

export default LandingBookPageBannerSection;
