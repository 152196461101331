/* eslint-disable react/prop-types */
import * as React from 'react';
import { withMarketingSection } from '../withMarketingSection';
import { Typography } from '../DesignSystemV2/Typography';
import styled from 'styled-components';
import { BuyNowButton } from './Buttons';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import ProgressiveImage from 'react-progressive-image';
import { colorsV2 } from '../../style/colors-v2';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
const Description = styled.div``;

const benefitModels = [
  {
    icon: 'O5GLyLKySEqMsanNHG5m',
    backgroundColor: colorsV2.primary100,
    text: 'Phương pháp Linear Tư duy & Thông Minh'
  },
  {
    icon: 'gsRp6YBQTVyEqZaANPM0',
    backgroundColor: colorsV2.purple100,
    text: 'Sách trình bày đẹp, rõ ràng, và rất dễ hiểu'
  },
  {
    icon: 'WLqh38qSP29SNjaSBDGi',
    backgroundColor: colorsV2.green100,
    text: 'Linear được chứng nhận sở hữu trí tuệ'
  }
];
const Benefits = styled.div`
  display: grid;
  grid-row-gap: 8px;

  @media ${deviceBreakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
  }
`;
const Benefit = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  padding: 24px;
  border-radius: 16px;

  @media ${deviceBreakpoints.md} {
    grid-auto-flow: row;
    grid-row-gap: 32px;
  }
`;
const BenefitLogo = styled.div`
  padding: 6px;
  border-radius: 12px;
  width: 52px;
  height: 52px;
  background-color: ${colorsV2.white100};

  img {
    width: 40px;
    height: 40px;
  }

  @media ${deviceBreakpoints.md} {
    width: 64px;
    height: 64px;
    img {
      width: 52px;
      height: 52px;
    }
  }
`;
const BookSpecialPointSectionContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  .youtube-inline-video {
    border-radius: 8px;
  }
  .special-points {
    margin-top: 52px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  ${BuyNowButton} {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${deviceBreakpoints.md} {
    .book-special-point-banner {
      max-width: 534px;
    }
  }

  @media ${deviceBreakpoints.lg} {
    .special-points {
      margin-top: 60px;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 8fr 4fr;
      grid-column-gap: 32px;
      img {
        max-width: 100%;
      }
      ${Benefits} {
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
      }
    }
  }
`;
const BookSpecialPointSection = () => (
  <BookSpecialPointSectionContainer>
    <Typography
      variant="bold/32-40"
      tabletVariant="bold/40-48"
      style={{ textAlign: 'center' }}
    >
      Điểm nổi bật của cuốn sách
    </Typography>
    <div className="special-points">
      <ProgressiveImage
        src={getAssetLinkByHandle({
          handle: 'npyFV2yhRmhS5H7259kA'
        })}
        placeholder={getAssetLinkByHandle({
          handle: 'npyFV2yhRmhS5H7259kA',
          width: 10
        })}
      >
        {src => (
          <img
            src={src}
            width="auto"
            height="auto"
            alt="home-banner"
            className="book-special-point-banner"
          />
        )}
      </ProgressiveImage>
      <Description>
        <Benefits>
          {benefitModels.map(({ text, icon, backgroundColor }, index) => (
            <Benefit key={index} style={{ backgroundColor: backgroundColor }}>
              <BenefitLogo>
                <img src={getAssetLinkByHandle({ handle: icon })} alt="book" />
              </BenefitLogo>
              <Typography
                variant="bold/16-24"
                tabletVariant="bold/24-32"
                style={{ color: colorsV2.white100 }}
              >
                {text}
              </Typography>
            </Benefit>
          ))}
        </Benefits>
      </Description>
    </div>
    <BuyNowButton />
  </BookSpecialPointSectionContainer>
);

export default withMarketingSection(BookSpecialPointSection, {
  selfish: true,
  style: { paddingTop: 80, paddingBottom: 60 },
  id: 'noi-bat'
});
